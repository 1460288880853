import { template as template_3b9ce134594a45e694d484edb62a69e2 } from "@ember/template-compiler";
const WelcomeHeader = template_3b9ce134594a45e694d484edb62a69e2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
