import { template as template_cdc159ef1ab640c286d8288a3f27bc7a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_cdc159ef1ab640c286d8288a3f27bc7a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
