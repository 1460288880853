import { template as template_5c5cfad73b2845ee86fddae096d95116 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5c5cfad73b2845ee86fddae096d95116(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
